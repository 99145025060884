import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function LockdownMerchantPromoOttawa () {
  const shareTitle = 'Ottawa Board of Trade, BIA coalition team up on discount, delivery programs to boost local businesses'
  const articleLink = 'https://obj.ca/article/local/ottawa-board-trade-bia-coalition-team-discount-delivery-programs-aid-local'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Ottawa Board of Trade, BIA coalition team up on discount, delivery programs to boost local businesses</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>March 12, 2021</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/ottawa-bot-bia-full.jpg" alt="FanSaves co-founders Kris McCarthy (left) and Shannon Ferguson are partnering with the Ottawa Board of Trade and the Ottawa Coalition of Business Improvement Areas on a discount program to help small businesses." />
                <div className="snippet">The following preview is from the <a href={articleLink} target="_blank" rel='noreferrer'>Ottawa Business Journal</a></div>
                <p>As independent retailers struggle to compete with big-box stores and e-commerce behemoths such as Amazon during the pandemic, two of Ottawa’s largest business organizations have joined forces with a pair of local startups to provide product discounts and free delivery service to members.</p>
                <p>Trexity provides same-day local delivery for businesses, giving local retailers a better opportunity to compete with the likes of Amazon, says founder Alok Ahuja. </p>
                <a href={articleLink} target='_blank' rel='noreferrer'>Read more at the Ottawa Business Journal</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/helping-local-businesses-during-lockdown" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>$25,000 for local businesses during lockdown</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
